:root ._dsp-inline{display:inline;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-auto{margin-right:auto;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-auto{margin-left:auto;}
:root ._col-43818401{color:var(--color-10);}
:root ._ff-299667014{font-family:var(--f-fa);}
:root ._fow-700{font-weight:700;}
:root ._ls-167744183{letter-spacing:var(--f-21-8);}
:root ._fos-229441344{font-size:var(--f-si-8);}
:root ._lh-222976697{line-height:var(--f-li-8);}
:root ._ussel-auto{user-select:auto;-webkit-user-select:auto;}
:root ._ta-center{text-align:center;}
:root ._maw-768px{max-width:768px;}
@media screen and (min-width: 801px) { :root:root:root ._col-_gtSm_43818401{color:var(--color-10);} }
@media screen and (min-width: 801px) { :root:root:root ._ff-_gtSm_299667014{font-family:var(--f-fa);} }
@media screen and (min-width: 801px) { :root:root:root ._fow-_gtSm_700{font-weight:700;} }
@media screen and (min-width: 801px) { :root:root:root ._ls-_gtSm_167744214{letter-spacing:var(--f-21-9);} }
@media screen and (min-width: 801px) { :root:root:root ._fos-_gtSm_229441375{font-size:var(--f-si-9);} }
@media screen and (min-width: 801px) { :root:root:root ._lh-_gtSm_222976728{line-height:var(--f-li-9);} }
:root ._col-43818246{color:var(--color-15);}
:root ._fow-233016109{font-weight:var(--f-we-3);}
:root ._ls-167744028{letter-spacing:var(--f-21-3);}
:root ._fos-229441189{font-size:var(--f-si-3);}
:root ._lh-222976542{line-height:var(--f-li-3);}
:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._jc-center{justify-content:center;}
:root ._ai-center{align-items:center;}
:root ._w-90px{width:90px;}
:root ._pt-1481558338{padding-top:var(--space-3);}
:root ._pb-1481558338{padding-bottom:var(--space-3);}
:root ._pr-1481558307{padding-right:var(--space-4);}
:root ._pl-1481558307{padding-left:var(--space-4);}
@media screen and (min-width: 661px) { :root:root:root ._w-_gtXs_160px{width:160px;} }
:root ._fd-row{flex-direction:row;}
:root ._jc-flex-start{justify-content:flex-start;}
:root ._ai-flex-start{align-items:flex-start;}
@media screen and (min-width: 801px) { :root:root:root ._fw-_gtSm_wrap{flex-wrap:wrap;} }
@media screen and (min-width: 801px) { :root:root:root ._fd-_gtSm_row{flex-direction:row;} }
:root ._ai-stretch{align-items:stretch;}
:root ._w-10037{width:100%;}
:root ._mt-1481558245{margin-top:var(--space-6);}
:root ._pr-1316331230{padding-right:var(--space-25);}
:root ._pl-1316331230{padding-left:var(--space-25);}
:root ._fw-wrap{flex-wrap:wrap;}
:root ._mr-0px{margin-right:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._fost-italic{font-style:italic;}
:root ._fow-300{font-weight:300;}
:root ._ls-167743997{letter-spacing:var(--f-21-2);}
:root ._fos-229441158{font-size:var(--f-si-2);}
:root ._lh-222976511{line-height:var(--f-li-2);}
:root ._col-137133827{color:var(--color-1);}
:root ._tt-uppercase{text-transform:uppercase;}
:root ._o-1{opacity:1;}
:root ._o-0{opacity:0;}
:root ._fow-233016171{font-weight:var(--f-we-5);}
:root ._ls-167744090{letter-spacing:var(--f-21-5);}
:root ._fos-229441251{font-size:var(--f-si-5);}
:root ._lh-222976604{line-height:var(--f-li-5);}
:root ._col-675002279{color:var(--color);}
@media screen and (min-width: 661px) { :root:root:root ._ff-_gtXs_299667014{font-family:var(--f-fa);} }
@media screen and (min-width: 661px) { :root:root:root ._fow-_gtXs_233016233{font-weight:var(--f-we-7);} }
@media screen and (min-width: 661px) { :root:root:root ._ls-_gtXs_167744152{letter-spacing:var(--f-21-7);} }
@media screen and (min-width: 661px) { :root:root:root ._fos-_gtXs_229441313{font-size:var(--f-si-7);} }
@media screen and (min-width: 661px) { :root:root:root ._lh-_gtXs_222976666{line-height:var(--f-li-7);} }
:root ._tt-lowercase{text-transform:lowercase;}
:root ._col-43818215{color:var(--color-16);}
:root ._fow-233016047{font-weight:var(--f-we-1);}
:root ._ls-167743966{letter-spacing:var(--f-21-1);}
:root ._fos-229441127{font-size:var(--f-si-1);}
:root ._lh-222976480{line-height:var(--f-li-1);}